import { conf } from '@/config/conf'
import axios from 'axios'
import store from '@/store'


export function getInterventionsOverviewRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getInterventionsOverviewByUserId',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemInterventionTypesRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getSystemInterventionTypes',
            method: "POST",
            data: { id },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getProjectInterventionTypesRequest(objectId: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getProjectInterventionTypes',
            method: "POST",
            data: { objectId },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemInterventionsRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getSystemInterventions',
            method: "POST",
            data: { id },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getInterventionByIdRequest(id: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getInterventionById',
            method: "POST",
            data: { id },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getSystemInterventionContactsRequest(systemId: any, interventionType: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getSystemInterventionContacts',
            method: "POST",
            data: { systemId, interventionType },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveSystemInterventionRequest(intervention: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/saveSystemIntervention',
            method: "POST",
            data: intervention,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function saveSystemInterventionContactsRequest(data: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/saveSystemInterventionContacts',
            method: "POST",
            data: data,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getInterventionTypesRequest() {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getInterventionTypes',
            method: "GET",
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function takeOverInterventionsRequest(systemIdFrom: any, systemIdTo: any, type: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/takeOverInterventions',
            method: "POST",
            data: { systemIdFrom: systemIdFrom, systemIdTo: systemIdTo, type: type },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function takeOverInterventionContactsRequest(systemIdFrom: any, systemIdTo: any, type: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/takeOverInterventionContacts',
            method: "POST",
            data: { systemIdFrom: systemIdFrom, systemIdTo: systemIdTo, type: type },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function deleteInterventionRequest(intervention: number | [], intType: any, systemId: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/deleteIntervention',
            method: "POST",
            data: { intervention, systemId, intType },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function printInterventionsOverviewRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/PDF/interventionsOverview',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function printContactsOverviewRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/PDF/contactsOverview',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function sendContactsToLisaRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/sendContactsToLisa',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function sendInterventionsToLisaRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/sendInterventionsToLisa',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getInterventionsLogsRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getInterventionLogs',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getInterventionContactsLogsRequest(args={}) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getInterventionContactLogs',
            method: "POST",
            data: args || {} ,
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}

export function getAvailableContactsForIntTypeRequest(systemId: any, interventionId: any) {
    return new Promise(( resolve, reject ) => {
        const request = axios({
            url: conf.apiUrl + '/Intervention/getAvailableContactsForIntType',
            method: "POST",
            data: { systemId, interventionId },
            headers: {
                xsrf: conf.xsrf,
                Authorization: 'Bearer ' + store.getters.user.accessToken
            }
        });

        request.then(function( res: any ) {
            resolve(res.data)
        });

        request.catch(function( reason: any) {
            reject(reason)
            // TODO:  general error handler !!
        });
    })
}