
import { Vue } from 'vue-class-component'
import {
	deleteInterventionRequest,
	getInterventionTypesRequest,
	getSystemInterventionsRequest
} from "@/services/InterventionService";
import { validateWeekday } from '@/services/Globals';

export default class ObjectSystemInterventions extends Vue {
	interventionTypes: any = {}
	interventions: any = {}
	
	get headline() {
		// const interventionName = this.interventionName
		const headline = this.$route.meta.headline
		return `Interventionen im Fall ${ headline }`
	}
	
	get interventionName() {
		return this.interventionTypes[this.interventionType]?.name
	}
	
	get systemId(): any {
		return this.$route.params.system
	}
	
	get interventionType(): any {
		return this.$route.params.type
	}
	
	newIntervention() {
		this.$router.push({ name: 'ObjectSystemInterventionNew' })
	}
	
	deleteIntervention(item: any) {
		const index = this.interventions[this.interventionType].items.indexOf(item)
		
		deleteInterventionRequest(item.id, this.interventionType, this.systemId).then((res: any) => {
			if ( res.result ) {
				this.interventions[this.interventionType].items.splice(index, 1)
			}
		})
	}
	
	takeOver() {
		this.$router.push({ name: 'InterventionTakeOver' })
	}
	
	editIntervention(id: any) {
		// kuchen
		this.$router.push({ name: 'ObjectSystemInterventionDetails', params: { intervention: id } })
	}
	
	beforeMount() {
		// get all interventionTypes
		getInterventionTypesRequest().then((res: any) => {
			this.interventionTypes = res.result
		})
		
	}
	
	mounted() {
		const interventionType = this.$route.params.type
		// get all Interventions
		getSystemInterventionsRequest(this.systemId).then((res: any) => {
			if ( res.result ) {
				const intKeys = Object.keys(res.result)
				intKeys.map((intervention: any) => {
					if ( res.result[intervention].items.length > 0 && intervention === interventionType) {
						res.result[intervention].items.sort((a: any, b: any) => a.timeStart - b.timeStart)
						res.result[intervention].items.map((item: any) => {
							const validateTime = validateWeekday(item.timeStart, item.timeEnd)
							item.timeStart = validateTime.start.hours+':'+validateTime.start.minutes
							item.timeEnd = validateTime.end.hours+':'+validateTime.end.minutes
							item.weekday = validateTime.weekday
						})
					} else if ( res.result[intervention].items.length === 0 && intervention === interventionType ) {
						//
					} else {
						delete res.result[intervention]
					}
				})
				this.interventions = res.result
			}
		})
	}
}
